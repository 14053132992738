.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #333333;
  font-size: 16px;
  margin-left: 1rem;
  transition: width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.circle.selected {
  background-color: #333333;
  color: #ffffff;
  width: 3rem;
  font-size: 20px;
  height: 3rem;
  transition: width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.circle.done {
  background-color: #333333;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  font-size: 16px;
  transition: width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.pointer:hover {
  cursor: pointer;
}

.segment-content {
  margin-top: 16pt;
  margin-left: 52pt;
  width: 500pt;
}

@media (max-width: 767px) {
  /* Extra small devices */
  .circle {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 14px;
  }
}
