@import "../../../styles/breakpoints";

.scrollable-column {
  // overflow-y: auto;
}

.fixed-column {
  position: sticky;
  top: 0;
  height: 100vh;

  @include media-breakpoint(md) {
    position: relative;
    height: auto;
  }
}
