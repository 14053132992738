.hero__section {
  background: var(--hero-bg);
}

.hero__content h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 600;
  margin: 20px 0px;
}

.hero__content p {
  color: var(--primary-color);
  line-height: 28px;
}

.hero__section p:nth-child(1) {
  font-weight: 500;
}

.buy__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 40px;
}

.buy__btn a {
  text-decoration: none;
  color: #fff;
}

.buy__btn a:hover {
  color: #fff;
}

.hero__content {
  padding-top: 45px;
}

.timer__count {
  background: var(--primary-color);
  height: 300px;
}

.timer__count img {
  width: 70% !important;
  height: 70% !important;
  object-fit: contain;
}

.store__btn {
  background: #fff;
  color: var(--primary-color) !important;
  font-weight: 600;
}

.store__btn a:hover {
  color: var(--primary-color);
}

@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 2rem;
  }

  .section__title {
    font-size: 1.7rem;
  }

  .counter__img {
    display: none;
  }

  .clock__top-content {
    text-align: center;
  }

  .clock__top-content h4 {
    font-size: 0.9rem !important;
  }

  .clock__top-content h3 {
    font-size: 1rem !important;
  }

  .count__down-col {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .hero__content h2 {
    font-size: 1.6rem;
  }

  .hero__content p {
    font-size: 0.9rem;
  }

  .buy__btn a {
    font-size: 0.9rem;
  }

  .section__title {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content {
    padding-top: 0;
    margin-bottom: 40px;
  }
}
