.form__group.read-only-input input,
.form__group.read-only-input textarea {
  border-color: transparent;
  padding: 0.5rem;
  color: gray;
  /* height: 12px; */
}

.preview-data {
  color: gray;
  margin: 0;
}
