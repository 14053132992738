.rectangle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rectangle {
  width: 320px;
  height: 80px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: border-color 0.3s;
}

.rectangle:hover {
  border-color: black;
}

.rectangle.selected {
  /* border-color: rgb(8, 52, 141); */
  border: 2px solid rgb(8, 52, 141);
}

.icon-placeholder {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-placeholder i {
  font-size: 32px;
}

.text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container p {
  color: black;
}

.rectangle-title {
  font-weight: bold;
  margin: 0;
  padding: 0;
  /* Add additional styling for titles here */
}

.rectangle-description {
  margin: 0;
  padding: 0;
  /* Add additional styling for descriptions here */
}
