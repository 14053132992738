.zoom-on-hover {
  transition: transform 0.2s;
}

.zoom-on-hover:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}

.hoverTable tr:hover {
  background-color: beige;
}
