.product__item {
  cursor: pointer;
}

.product__info h3 a {
  font-size: 1.2rem;
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 15px;
}

.product__info a:hover {
  color: inherit;
}

.product__info span {
  font-size: 0.9rem;
}

.product__card-bottom .price {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 500;
}

.product__card-bottom span i {
  font-size: 1.2rem;
  padding: 5px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
}

.blur-load {
  background-size: cover;
  background-position: center;
  position: relative;
}

.blur-load::before {
  content: "";
  position: absolute;
  inset: 0;
  animation: pulse 2.5s infinite;
  background-color: rgba(255, 255, 255, 0.3);
}

.blur-load.loaded > img {
  opacity: 1;
}

.blur-load.loaded::before {
  content: none;
}

.blur-load > img {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.product__img {
  aspect-ratio: 16 / 9;
  width: 100%;
  object-position: center;
  object-fit: cover;
  display: block;
}

@media only screen and (max-width: 992px) {
  .product__info h3 a {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 768px) {
  .product__info h3 a {
    font-size: 1rem;
  }

  .product__card-bottom span i {
    font-size: 1rem;
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  50% {
    background-color: rgba(255, 255, 255, 0);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
