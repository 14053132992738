$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin media-breakpoint($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media (max-width: $breakpoint-value) {
    @content;
  }
}
